import React from "react";
import styled from "styled-components";
import twitter from "../../assets/logos/twitter.svg";
import linkedin from "../../assets/logos/linkedin.svg";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
  }
  column-gap: 2rem;
`;

const Para1 = styled.p`
  // for all texts of font size 20px
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1em;
  line-height: 145%;
  margin: 0;
  @media only screen and (max-width: 767px) {
    font-size: 0.875em;
  }
  @media only screen and (max-width: 567px) {
    padding-bottom: 10px;
  }
  padding-bottom: 20px;
`;

const Para2 = styled.p`
  // for all texts of font size 20px
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  font-size: 1em;
  line-height: 145%;
  margin: 0;
  @media only screen and (max-width: 767px) {
    font-size: 0.875em;
  }
  @media only screen and (max-width: 567px) {
    padding-bottom: 15px;
  }
  padding-bottom: 20px;
`;

const Para3 = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.625em;
  @media screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 1.375em;
  }

  line-height: 145%;
  color: #32425c;
  margin: 0;
  padding-bottom: 10px;
`;

const ColumnNew = styled.div`
  float: left;
  padding: 10px;
  position: relative;
  right: 10px;
  img {
    cursor: pointer;
  }
`;

const GridItem = styled.div`
  hr {
    border: 2px solid #36455a;
  }
  @media only screen and (max-width: 567px) {
    padding-bottom: 25px;
  }
`;

const FifthComponent = () => {
  let mailtoHref = "mailto:comms@avantari.org?subject=Query&body=Description";
  let mailtoHrefA = "mailto:social@avantari.org?subject=Query&body=Description";
  let mailtoHrefB =
    "mailto:careers@avantari.org?subject=Query&body=Description";

  return (
    <div style={{ marginBottom: `20px` }}>
      <GridContainer>
        <GridItem>
          <hr />
          <Para1>Want to collaborate?</Para1>
          <Para2>Work with us</Para2>
          <Para3 href={mailtoHref}>comms@avantari.org</Para3>
        </GridItem>
        <GridItem>
          <hr />
          <Para1>Want to be part of our newsletter?</Para1>
          <Para2>Sign up by dropping a line to</Para2>
          <Para3 href={mailtoHrefA}>social@avantari.org</Para3>
        </GridItem>

        <GridItem>
          <hr />
          <Para1>Want to join us?</Para1>
          <Para2>Send in your CV to</Para2>
          <Para3 href={mailtoHrefB}>careers@avantari.org</Para3>
        </GridItem>
        <GridItem>
          <hr />
          <Para1>Want to stay on top of our updates?</Para1>
          <Para2>Follow us on</Para2>
          <div>
            {/* <ColumnNew>
              <a href="https://example.com/" target="_blank" rel="noreferrer">
                <img src={instagram} alt="Snow" width="100%" height="100%" />
              </a>
            </ColumnNew> */}
            <ColumnNew>
              <a
                href="https://twitter.com/avantaritech?lang=en"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="Forest" width="100%" height="100%" />
              </a>
            </ColumnNew>
            <ColumnNew>
              <a
                href="https://in.linkedin.com/company/avantari"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedin}
                  alt="Mountains"
                  width="100%"
                  height="100%"
                />
              </a>
            </ColumnNew>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer></GridContainer>
    </div>
  );
};

export default FifthComponent;
