import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SeoComponent = ({ title, description }) => {
  const image = "https://dhyanaimage.s3.amazonaws.com/avantariLogo.svg";
  const location = useLocation();
  const canonical = "" + location.pathname;
  return (
    <>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-XX8M7V1JPR"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-XX8M7V1JPR');
</script> */}
        <title> {title} </title>
        {/* <meta
          name="google-site-verification"
          content="gVTuyEyMJ05PJYd8Q5Fk8JPyXmH68NlVoSuDgUv-SOs"
        /> */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta name="robots" content="index, follow" />
        <meta property="og:site_name" content="" />

        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonical} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="180" />
        <meta property="og:image:height" content="110" />
        <meta property="og:updated_time" content="1440432930" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={canonical} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />

        <link rel="canonical" href={canonical} />
      </Helmet>
    </>
  );
};

export default SeoComponent;
