// import React, { useState, useRef, useEffect } from "react";
// // import lottie from "lottie-web";
// import Lottie from "react-lottie-player";
// import animationData1 from "../../assets/lottieAvantari/ar.json";

// const useIntersection = (element, rootMargin) => {
//   const [isVisible, setState] = useState(false);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setState(entry.isIntersecting);
//           observer.unobserve(element.current);
//         }
//       },
//       {
//         rootMargin,
//       }
//     );

//     element.current && observer.observe(element.current);

//     return () => {
//       observer.unobserve(element.current);
//     };
//   }, [element, rootMargin]);

//   return isVisible;
// };

// const servicesDataHome = [
//   {
//     id: 0,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/ar.json",
//     title: "AR, VR",
//     title2: "& MR",
//     containerID: "containerLottie1",
//   },
//   {
//     id: 1,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/appDev.json",
//     title: "Application",
//     title2: "Development",
//     containerID: "containerLottie2",
//   },
//   {
//     id: 2,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/webDev.json",
//     title: "Website",
//     title2: "Development",
//     containerID: "containerLottie3",
//   },
//   {
//     id: 3,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/uiux.json",
//     title: "Ui & Ux",
//     title2: "Design",
//     containerID: "containerLottie4",
//   },
// ];
// const servicesDataHome1 = [
//   {
//     id: 0,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/ml.json",
//     title: "Machine",
//     title2: "Learning",
//     containerID: "containerLottie5",
//   },
//   {
//     id: 1,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/sw.json",
//     title: "Software",
//     title2: "Development",
//     containerID: "containerLottie6",
//   },
//   {
//     id: 2,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/design.json",
//     title: "Design &",
//     title2: "Prototype",
//     containerID: "containerLottie7",
//   },
//   {
//     id: 3,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/cad.json",
//     title: "CAD &",
//     title2: "Renders",
//     containerID: "containerLottie8",
//   },
// ];
// const servicesDataHome2 = [
//   {
//     id: 0,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/wireless.json",
//     title: "Wireless",
//     title2: "Communication",
//     containerID: "containerLottie9",
//   },
//   {
//     id: 1,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/rapid.json",
//     title: "Rapid",
//     title2: "Prototyping",
//     containerID: "containerLottie10",
//   },

//   {
//     id: 2,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/embed.json",
//     title: "Embedded Design Systems",
//     title2: "& Architecture",
//     containerID: "containerLottie11",
//   },
//   {
//     id: 3,
//     img: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/pcb.json",
//     title: "High Density",
//     title2: "PCB Design",
//     containerID: "containerLottie12",
//   },
// ];

// // const SixthComponentThirdRow = () => {
// //   const refLottie2 = useRef();

// //   const inViewport2 = useIntersection(refLottie2, "-200px");

// //   useEffect(() => {
// //     for (var i = 0; i < servicesDataHome2.length; i++) {
// //       let illust = document.getElementById(servicesDataHome2[i].containerID);
// //       var params = {
// //         container: illust,
// //         name: "passcode",
// //         autoplay: true,
// //         loop: false,
// //         path: servicesDataHome2[i].img,
// //         renderer: "svg",
// //       };
// //       lottie.loadAnimation(params);
// //     }
// //   });

// //   return (
// //     <>
// //       <div ref={refLottie2} className="newDiv" id="insideMarginRow">
// //         <div className="capabilitiesGrid">
// //           {servicesDataHome2.map((services2, index) => (
// //             <div className="capabilitiesGrid-item" key={index}>
// //               <div
// //                 id={inViewport2 ? services2.containerID : ""}
// //                 style={{
// //                   width: `100px`,
// //                   height: `100px`,
// //                 }}
// //                 className="lottieDiv"
// //               ></div>
// //               <span className="caption">
// //                 {services2.title}
// //                 <br />
// //                 {services2.title2}
// //               </span>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // const SixthComponentSecondRow = () => {
// //   const refLottie1 = useRef();

// //   const inViewport1 = useIntersection(refLottie1, "-150px");

// //   useEffect(() => {
// //     for (var i = 0; i < servicesDataHome1.length; i++) {
// //       let illust = document.getElementById(servicesDataHome1[i].containerID);
// //       var params = {
// //         container: illust,
// //         name: "passcode",
// //         autoplay: true,
// //         loop: false,
// //         path: servicesDataHome1[i].img,
// //         renderer: "svg",
// //       };
// //       lottie.loadAnimation(params);
// //     }
// //   });

// //   return (
// //     <>
// //       <div ref={refLottie1} className="newDiv" id="insideMarginRow">
// //         <div className="capabilitiesGrid">
// //           {servicesDataHome1.map((services1, index) => (
// //             <div className="capabilitiesGrid-item" key={index}>
// //               <div
// //                 id={inViewport1 ? services1.containerID : ""}
// //                 style={{
// //                   width: `100px`,
// //                   height: `100px`,
// //                 }}
// //                 className="lottieDiv"
// //               ></div>
// //               <span className="caption">
// //                 {services1.title}
// //                 <br />
// //                 {services1.title2}
// //               </span>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// const SixthComponent = () => {
//   const refLottie = useRef();

//   const inViewport = useIntersection(refLottie, "-100px");

//   useEffect(() => {
//     // for (var i = 0; i < servicesDataHome.length; i++) {
//     //   let illust = document.getElementById(servicesDataHome[i].containerID);
//     //   var params = {
//     //     container: illust,
//     //     name: "passcode",
//     //     autoplay: true,
//     //     loop: false,
//     //     path: servicesDataHome[i].img,
//     //     renderer: "svg",
//     //   };
//     //   lottie.loadAnimation(params);
//     // }
//   });

//   return (
//     <>
//       <div ref={refLottie} className="newDiv" id="insideMargin">
//         <div className="capabilitiesGrid">
//           {/* {servicesDataHome.map((services, index) => ( */}
//           {/* <div className="capabilitiesGrid-item"> */}
//           <Lottie
//             loop={inViewport ? false : true}
//             // loop={false}
//             // loop
//             animationData={animationData1}
//             // speed="1"
//             play
//             // style={{
//             //   width: `100px`,
//             //   height: `100px`,
//             // }}
//             // className="lottieDiv"
//           />
//           {/* <div
//                 id={inViewport ? services.containerID : ""}
//                 style={{
//                   width: `100px`,
//                   height: `100px`,
//                 }}
//                 className="lottieDiv"
//               ></div> */}
//           {/* <span className="caption">
//                 {services.title}
//                 <br />
//                 {services.title2}
//               </span> */}
//           {/* </div> */}
//           {/* ))} */}
//         </div>
//         {/* <div>
//           <SixthComponentSecondRow />
//         </div>
//         <div>
//           <SixthComponentThirdRow />
//         </div> */}
//       </div>
//     </>
//   );
// };

// export default SixthComponent;

import { useEffect, useRef, useState } from "react";
import "../styles/style.scss";
import { useHistory } from "react-router-dom";
import animationData1 from "../../assets/lottieAvantari/ar.json";
import animationData2 from "../../assets/lottieAvantari/appDev.json";
import animationData3 from "../../assets/lottieAvantari/webDev.json";
import animationData4 from "../../assets/lottieAvantari/uiux.json";
import animationData5 from "../../assets/lottieAvantari/ml.json";
import animationData6 from "../../assets/lottieAvantari/sw.json";
import animationData7 from "../../assets/lottieAvantari/design.json";
import animationData8 from "../../assets/lottieAvantari/cad.json";
import animationData9 from "../../assets/lottieAvantari/wireless.json";
import animationData10 from "../../assets/lottieAvantari/rapid.json";
import animationData11 from "../../assets/lottieAvantari/embed.json";
import animationData12 from "../../assets/lottieAvantari/pcb.json";
import Lottie from "react-lottie-player";
const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      {
        rootMargin,
      }
    );
    element.current && observer.observe(element.current);
    return () => {
      observer.unobserve(element.current);
    };
  }, [element, rootMargin]);
  return isVisible;
};
function SixthComponent() {
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const inViewport = useIntersection(ref, "0px");
  const inViewport1 = useIntersection(ref1, "-50px");
  const inViewport2 = useIntersection(ref2, "-100px");
  if (inViewport) {
    // console.log("in viewport:", ref.current);
  }
  return (
    <div>
      <div className="newDiv" ref={ref} id="insideMarginRow1">
        <div
          className=" "
          // id={inViewport ? "fadeInBottom" : "notVisible"}
        >
          <div className="capabilitiesGrid">
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport ? false : true}
                // loop
                animationData={animationData1}
                speed="1"
                play
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />
              <span className="caption">
                AR, VR
                <br />& MR
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport ? false : true}
                animationData={animationData2}
                speed="1"
                play
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />{" "}
              <span className="caption">
                Application
                <br />
                Development
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport ? false : true}
                animationData={animationData3}
                play
                speed="1"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />
              <span className="caption">
                Website
                <br />
                Development
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport ? false : true}
                animationData={animationData4}
                play
                speed="1"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />
              <span className="caption">
                Ui & Ux
                <br />
                Design
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="newDiv" ref={ref1} id="insideMarginRow1">
        <div
          className=" "
          // id={inViewport1 ? "fadeInBottom" : "notVisible"}
        >
          <div className="capabilitiesGrid">
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport1 ? false : true}
                animationData={animationData5}
                speed="1"
                play
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />{" "}
              <span className="caption">
                Machine
                <br />
                Learning
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport1 ? false : true}
                animationData={animationData6}
                speed="1"
                play
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />{" "}
              <span className="caption">
                Software
                <br />
                Development
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport1 ? false : true}
                animationData={animationData7}
                play
                speed="1"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />{" "}
              <span className="caption">
                Design &
                <br />
                Prototype
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport1 ? false : true}
                animationData={animationData8}
                play
                speed="1"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />{" "}
              <span className="caption">
                CAD &
                <br />
                Renders
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="newDiv" ref={ref2} id="insideMarginRow1">
        <div
          className=" "
          // id={inViewport2 ? "fadeInBottom" : "notVisible"}
        >
          <div className="capabilitiesGrid">
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport2 ? false : true}
                animationData={animationData9}
                speed="1"
                play
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />
              <span className="caption">
                Wireless
                <br />
                Communication
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport2 ? false : true}
                animationData={animationData10}
                speed="1"
                play
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />{" "}
              <span className="caption">
                Rapid
                <br />
                Prototyping
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport2 ? false : true}
                animationData={animationData11}
                play
                speed="1"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />{" "}
              <span className="caption">
                Embedded Design Systems
                <br />& Architecture
              </span>
            </div>
            <div className="capabilitiesGrid-item">
              <Lottie
                loop={inViewport2 ? false : true}
                animationData={animationData12}
                play
                speed="1"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDiv"
              />
              <span className="caption">
                High Density
                <br />
                PCB Design
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SixthComponent;
