import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import "../styles/style.scss";

function LazyImage({ className, src, alt, lqip, aspectRatio = 2 / 3 }) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);
  return (
    <div id="firstcomp">
      <div className={clsx("wrapper", className)}>
        <div 
        className="lazyAspect"
        // style={{paddingBottom:`55%`}} 
        />
        {lqip && <img src={lqip} alt="" aria-hidden="true" />}
        <img
          loading="lazy"
          src={src}
          alt={alt}
          ref={imgRef}
          onLoad={() => setLoaded(true)}
          className={clsx("source", loaded && "loaded")}
        />
      </div>
    </div>
  );
}

export default LazyImage;
