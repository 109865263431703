import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/style.scss";
import colon from "../../assets/colon.svg";
import testimonialMob from "../../assets/colon.svg";

import left from "../../assets/left.svg";
import right from "../../assets/right.svg";

import econTimes from "../../assets/testimonialsLogoNew/econTimes.svg";
import sakshiPost from "../../assets/testimonialsLogoNew/sakshiPost.svg";
import bangaloreMirror from "../../assets/testimonialsLogoNew/bangaloreMirror.svg";
import asianAge from "../../assets/testimonialsLogoNew/asianAge.svg";
import yourStory from "../../assets/testimonialsLogoNew/yourStory.svg";
import finanExp from "../../assets/testimonialsLogoNew/finanExp.svg";
import newIndianExp from "../../assets/testimonialsLogoNew/newIndianExp.svg";
import indianExp from "../../assets/testimonialsLogoNew/indianExp.svg";
import toi from "../../assets/testimonialsLogoNew/toi.svg";
import indiaToday from "../../assets/testimonialsLogoNew/indiaToday.svg";
import theHindu from "../../assets/testimonialsLogoNew/theHindu.svg";
import mint from "../../assets/testimonialsLogoNew/mint.svg";

class ThirdComponent extends React.Component {
  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <button
          className="arrow-btn prev"
          onClick={() => this.slider.slickPrev()}
        >
          <img src={left} width="100%" height="100%" alt="" />
        </button>
        <button
          className="arrow-btn next"
          onClick={() => this.slider.slickNext()}
        >
          <img src={right} width="100%" height="100%" alt="" />
        </button>
      </div>
    );
  };

  render() {
    const settings = {
      centerPadding: "0px",
      centerMode: true,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };

    var images = [
      {
        img: econTimes,
        width: "",
        link: "https://economictimes.indiatimes.com/magazines/panache/how-this-ar-driven-project-helped-naveen-patnaik-win-odisha-assembly-polls/articleshow/69968686.cms",
        alt: "The Economic Times",
        describe:
          "Avantari combined AR with 3D holographic technology to become the first entity to create life sized Augmented Reality",
      },
      {
        img: indiaToday,
        width: "",
        link: "https://www.indiatoday.in/magazine/supplement/story/20160307-bhairav-shankar-flip-health-heart-fitness-monitor-avantari-technologies-733502-2016-02-23",
        alt: "India Today",
        describe:
          "This compact device offers a quick snapshot of various aspects of your health on the go",
      },
      {
        img: mint,
        width: "",
        link: "https://www.livemint.com/sports/olympics-news/tokyo-olympics-dhyana-partners-heartfulness-institute-for-indian-contingent-11626867971638.html",
        alt: "Mint",
        describe:
          "This entire experience of data-driven meditation for our players will contribute significantly in enhancing their preparedness",
      },
      {
        img: theHindu,
        width: "",
        link: "https://www.thehindu.com/sci-tech/technology/ar-x-ar-now-an-augmented-reality-app-for-ar-rahman/article22668397.ece",
        alt: "The Hindu",
        describe:
          "A R Rahman is among the first in the film industry to have an AR app dedicated to him.",
      },
      {
        img: finanExp,
        width: "",
        link: "https://www.financialexpress.com/industry/technology/dhyana-new-age-meditation-tracking-device-that-guides-you-through-effective-meditation-sessions/2104676/",
        alt: "Financial Express",
        describe:
          "With this innovative wearable you can master the practise of mindfulness and gain tangible benefits for your mind and body",
      },
      {
        img: asianAge,
        width: "",
        link: "https://www.asianage.com/life/more-features/040617/augmented-reality-in-a-supermarket.html",
        alt: "Asian Age",
        describe:
          "Entering the retail space for the first time in the world with life-size AR opens the market to dynamic and thrilling shopping experiences ",
      },
      // {
      //   img: bangaloreMirror,
      //   width: "",
      //   link: "https://bangaloremirror.indiatimes.com/news/india/pv-sindhu-to-raise-awareness-on-breast-cancer-using-augmented-reality-technology/articleshow/71482181.cms",
      //   alt: "Bangalore Mirror",
      //   describe:
      //     "The idea of using AR in this app is to have a one-on-one interaction with a doctor, without him or her being physically present there.",
      // },
      // {
      //   img: sakshiPost,
      //   width: "",
      //   link: "https://english.sakshi.com/news/sports/pullela-gopichand-launches-dhyana-sports-guided-meditation-sessions-127480",
      //   alt: "Sakshi Post",
      //   describe:
      //     "The meditation-tracking feature can also be accessed without the ring in the ‘Dhyana Lite’ mode where a finger placed over the smartphone camera",
      // },
      {
        img: toi,
        width: "",
        link: "https://timesofindia.indiatimes.com/city/hyderabad/start-ups-come-up-with-innovative-solutions-to-meet-covid-challenge/articleshow/75018141.cms",
        alt: "Times of India",
        describe:
          "The team has built a working ventilator prototype which is capable of delivering a rate of 0-30 BPM and tidal volume to 200-800ml",
      },
      {
        img: yourStory,
        width: "",
        link: "https://yourstory.com/2018/11/5-smart-wearables-qualcomm-design-india-challenge/amp",
        alt: "YourStory",
        describe:
          "Smart cardiac monitoring equipment which uses AR and AI can enable home-based and real-time diagnostics.",
      },

      {
        img: indianExp,
        width: "",
        link: "https://indianexpress.com/article/olympics/ioa-ropes-in-meditation-tracking-start-up-to-work-for-mental-well-being-of-olympic-bound-athletes-7401363/",
        alt: "The Indian Express",
        describe:
          "The smart Dhyana ring is capable of measuring the amount of time one is actually focussing while in a meditation session",
      },
      {
        img: theHindu,
        width: "",
        link: "https://www.thehindu.com/entertainment/movies/regular-is-boring-for-rana-daggubati-who-is-awaiting-the-release-of-nene-raju-nene-mantri-and-revels-in-constantly-testing-new-waters/article19307420.ece",
        alt: "The Hindu",
        describe:
          "Taking a picture with the actors, enabled by AR, helps record a memory",
      },

      // {
      //   img: newIndianExp,
      //   width: "",
      //   link: "https://www.newindianexpress.com/lifestyle/tech/2021/jul/15/wearable-technology-a-training-ring-for-the-mind-2330207.html",
      //   alt: "New Indian Express",
      //   describe:
      //     "This ring would help Indian Olympic athletes stay calm and focused",
      // },
    ];
    

    var imgSlides1 = () =>
      images.map((num, index) => (
        <div className="divSLide" id="insideMargin" key={index}>
          <div className="deskColon">
            <img
              src={colon}
              style={{ textAlign: `center`, margin: `0 auto` }}
              width="50"
              height="40"
              alt=""
            />
          </div>
          <div className="mobColon">
            <img
              src={testimonialMob}
              style={{ textAlign: `center`, margin: `0 auto` }}
              width=""
              height=""
              alt=""
            />
          </div>
          <p className="paragraphDiv" id="thirdComponent">
            <a
              href={num.link}
              target="_blank"
              style={{
                textDecoration: `none`,
                cursor: `pointer`,
                color: `#32425c`,
              }}
              rel="noreferrer"
            >
              {num.describe}
            </a>
          </p>
          <hr />
          <img
            height="100%"
            src={num.img}
            alt=""
            width="280px"
            style={{
              textAlign: `center`,
              margin: `0 auto`,
              marginBottom: `30px`,
            }}
          />
        </div>
      ));

    return (
      <div className="content-wrapperFeatureIcons rep1">
        <p className="title">Testimonials</p>
        <div>
          <Slider
            ref={(c) => (this.slider = c)}
            {...settings}
            slidesToShow={1}
            autoplay={false}
            arrows={false}
            autoplaySpeed={4000}
          >
            {imgSlides1()}
          </Slider>
        </div>
        {this.renderArrows()}
      </div>
    );
  }
}

export default ThirdComponent;
