import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import FifthComponent from "../../components/fifth-component";
import FirstComponent from "../../components/first-component";
import SecondComponent from "../../components/second-component";
import SeoComponent from "../../components/seo-component";
import SixthComponent from "../../components/sixth-component";
import ThirdComponent from "../../components/third-component";
import downarrow from "../../assets/lottieAvantari/downarrow.json";
import Lottie from "react-lottie-player";
const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  position: relative;
`;

const MarginDiv = styled.div`
  margin-left: 5vw;
  margin-right: 5vw;
`;

const MarginTopBottomFirst = styled.div`
  margin-top: 100px;
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    margin-top: 80px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 60px;
  }
`;

const MarginTopBottomFirst1 = styled.div`
  margin-top: 60px;
  height: 100%;
`;

const HomePage = (props) => {
  const projRef = useRef();

  const handleScrollDown = () =>
    projRef.current.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    // let arrowId = document.getElementById("arrowId");
    // var params = {
    //   container: arrowId,
    //   name: "arrow",
    //   autoplay: true,
    //   loop: true,
    //   path: "https://dhyanaimage.s3.amazonaws.com/lottieAvantari/downarrow.json",
    //   renderer: "svg",
    // };
    // lottie.loadAnimation(params);
  });

  return (
    <>
      <SeoComponent title="Avantari" description="" />
      <MarginTopBottomFirst1>
        <ContentWrapper>
          <MarginDiv>
            <FirstComponent />
            <div
              className="capabilitiesGridHomepage"
              onClick={handleScrollDown}
            >
              <Lottie
                loop
                animationData={downarrow}
                speed="1"
                play
                id="arrowId"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDivHome"
              />
              {/* <div
                id="arrowId"
                style={{
                  width: `100px`,
                  height: `100px`,
                }}
                className="lottieDivHome"
              ></div> */}
            </div>
          </MarginDiv>
        </ContentWrapper>
      </MarginTopBottomFirst1>
      <ContentWrapper>
        <MarginDiv>
          <MarginTopBottomFirst>
            <div ref={projRef}>
              <SecondComponent />
            </div>
          </MarginTopBottomFirst>
          <MarginTopBottomFirst>
            <p className="title">Capabilities</p>
            <SixthComponent />
          </MarginTopBottomFirst>
          <MarginTopBottomFirst>
            <ThirdComponent />
          </MarginTopBottomFirst>
          <MarginTopBottomFirst>
            <FifthComponent />
          </MarginTopBottomFirst>
        </MarginDiv>
      </ContentWrapper>
    </>
  );
};
export default HomePage;
